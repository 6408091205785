// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 830px;
			margin: 0 auto;
			text-align: center;

			p {
				font-size: 20px;
				font-weight: 300;

				@include media-breakpoint-up(xl) {
					font-size: 24px;
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;

		.scroll-to-content {
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}


// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}


// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// icon-collection-section
// =========================================================================
.icon-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: -45px;

				.item {
					margin-bottom: 45px;

					@include media-breakpoint-down(xs) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						flex: 1 !important;
						max-width: none !important;
					}

					.card {
						background: none;
						border: none;
						border-radius: 0;

						.card-image {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 70px;
							margin: 0 auto 15px auto;

							i {
								font-size: 40px;
							}
						}

						.card-body {
							padding: 0;
							text-align: center;

							.card-caption {
								.card-title {
									margin-bottom: 5px;
									color: $black;
									font-size: 18px;
									font-weight: 300;
									font-family: $font-family-secondary;
								}

								.card-subtitle {
									margin: 0;
									font-size: 14px;
								}

								.card-description {
									font-size: 14px;

									p {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// square-collection-section
// =========================================================================
.square-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.item {

			.card {
				border-radius: 0;
				border: none;
				aspect-ratio: 1;

				.card-img-overlay {
					padding: 0;
					border-radius: 0;
					background: transparent;

					.card-caption {
						color: $white;
						text-shadow: none;
						text-align: center;

						.card-title {
							margin-bottom: 0;

							&:hover {
								color: inherit;
							}
						}

						.card-subtitle {
							margin: 10px 0 0 0;
						}

						.card-description {
							display: none;
						}
					}

				}
			}

			&:nth-child(1),
			&:nth-child(3),
			&:nth-child(8) {
				.card {
					background-color: $purple-dark;

					.card-img-overlay {
						top: auto;
						width: 100%;
						height: 100%;
						max-width: 293px;
						max-height: 168px;
						background: url('/images/card-wave-purple-dark.svg') no-repeat left bottom;
						background-size: 100%;
						display: flex;
						flex-direction: column;

						.card-caption {
							flex: 0;
							margin-top: auto;
							padding: 20px 0 20px 20px;
							text-align: left;

							.card-title {
								margin-bottom: 0;

								&:hover {
									color: inherit;
								}
							}

							.card-subtitle {
								display: none;
							}
						}
					}
				}
			}

			&:nth-child(2) {
				.card {
					background: url('/images/visual-bird.svg') no-repeat left bottom $green-dark;

					@include media-breakpoint-down(lg) {
						background-size: 65%;
					}

					&:hover {
						background-color: lighten($green-dark, 5%);
						background-position: -10% 110%;
					}

					.card-img-overlay {
						display: flex;
						flex-direction: column;

						.card-caption {
							flex: 0;
							margin: auto;
							padding: 20px;

							.card-title {
								font-size: 18px;
								font-weight: 300;
								text-transform: uppercase;

								&:hover {
									color: inherit;
								}
							}
						}
					}
				}
			}

			&:nth-child(4) {
				.card {
					background: url('/images/visual-shell.svg') no-repeat left bottom $orange;

					@include media-breakpoint-down(lg) {
						background-size: 65%;
					}

					&:hover {
						background-color: lighten($orange, 10%);
						background-position: -10% 110%;
					}

					.card-img-overlay {
						display: flex;
						flex-direction: column;

						.card-caption {
							flex: 0;
							margin: auto;
							padding: 20px;

							.card-title {
								font-size: 18px;
								font-weight: 300;
								text-transform: uppercase;
							}
						}
					}
				}
			}

			&:nth-child(5) {
				.card {
					.card-img-overlay {
						display: none;
					}
				}
			}

			&:nth-child(6),
			&:nth-child(7) {
				.card {
					background-color: $green-dark;

					.card-img-overlay {
						top: auto;
						width: 100%;
						height: 100%;
						max-width: 293px;
						max-height: 168px;
						background: url('/images/card-wave-green.svg') no-repeat left bottom;
						background-size: 100%;
						display: flex;
						flex-direction: column;

						.card-caption {
							flex: 0;
							margin-top: auto;
							padding: 20px 0 20px 20px;
							text-align: left;

							.card-title {
								margin-bottom: 0;
							}

							.card-subtitle {
								display: none;
							}
						}
					}
				}
			}

			&:nth-child(9) {
				.card {
					background: url('/images/visual-leaves.svg') no-repeat right bottom $blue-light;

					@include media-breakpoint-down(lg) {
						background-size: 65%;
					}

					&:hover {
						background-color: lighten($blue-light, 10%);
						background-position: 110% 110%;
					}

					.card-img-overlay {
						display: flex;
						flex-direction: column;

						.card-caption {
							flex: 0;
							margin: auto;
							padding: 20px 30px;

							.card-title {
								font-size: 18px;
								font-weight: 300;
								text-transform: uppercase;

								&:hover {
									color: inherit;
								}
							}
						}
					}
				}
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	margin: 8vh 0;
	overflow: hidden;

	.container-two-columns {
		.container-holder {
			align-items: center;

			.column {
				&.narrow {
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}
				}

				&.wide {
					@include media-breakpoint-down(sm) {
						order: -1;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(8);
					}
				}

				&.one {
					&.narrow {
						@include media-breakpoint-only(md) {
							padding-right: 15px !important;
						}

						@include media-breakpoint-only(lg) {
							padding-right: 20px !important;
						}

						@include media-breakpoint-up(xl) {
							padding-right: 40px !important;
						}
					}

					&.wide {
						@include media-breakpoint-up(md) {
							padding-right: 15px !important;
							border-right: 1px solid $black;
						}

						@include media-breakpoint-up(lg) {
							padding-right: 20px !important;
						}

						align-self: stretch;

						.resource-image {
							display: flex;
							justify-content: flex-end;
							height: 100%;

							@include media-breakpoint-down(sm) {
								padding-bottom: 20px;
								border-bottom: 1px solid $black;
							}

							img {
								height: 100%;
								border-radius: 0 50px 0 0;
								object-fit: cover;

								@include media-breakpoint-up(xl) {
									width: 100vw;
									max-width: 1200px;
									max-height: 470px;
								}
							}
						}
					}
				}

				&.two {
					&.narrow {
						@include media-breakpoint-only(md) {
							padding-left: 15px !important;
						}

						@include media-breakpoint-only(lg) {
							padding-left: 20px !important;
						}

						@include media-breakpoint-up(xl) {
							padding-left: 40px !important;
						}
					}

					&.wide {
						@include media-breakpoint-up(md) {
							padding-left: 15px !important;
							border-left: 1px solid $black;
						}

						@include media-breakpoint-up(lg) {
							padding-left: 20px !important;
						}

						align-self: stretch;

						.resource-image {
							height: 100%;

							@include media-breakpoint-down(sm) {
								padding-bottom: 20px;
								border-bottom: 1px solid $black;
							}

							img {
								height: 100%;
								border-radius: 50px 0 0 0;
								object-fit: cover;

								@include media-breakpoint-up(xl) {
									width: 100vw;
									max-width: 1200px;
									max-height: 470px;
								}
							}
						}
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.item {
			&:nth-child(3n+1) {
				.card {
					background-color: $purple-dark;
				}
			}

			&:nth-child(3n+2) {
				.card {
					background-color: $green-dark;
				}
			}

			&:nth-child(3n+3) {
				.card {
					background-color: $orange;
				}
			}

			.card {
				@extend .default-card;
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
