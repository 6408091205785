& {
	position: relative;
	max-width: 2000px;
	margin: 0 auto;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 960px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// headings
h1,
h2 {
	margin-bottom: 30px;
}

h2,
h5 {
	font-weight: 300;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 20px;
		}

		.card-caption {
			display: flex;
			flex-direction: column;
			color: $white;

			.card-title-link {
				&:hover {
					color: $white;

					.card-title {
						color: inherit;
					}
				}
			}

			.card-subtitle {
				font-size: 18px;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		&.large-item {
			.gallery-link {
				border-radius: 50px 0 0 0;
			}
		}
	}
}
