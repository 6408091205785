// mini-sab
.mini-sab {
	@include media-breakpoint-down(md) {
		margin-top: 40px;
	}

	.container,
	.container-fluid {
		position: relative;

		.container-holder {
			z-index: 999;
			position: relative;

			.mini-search-book {
				@include media-breakpoint-down(md) {
					max-width: 330px;
					margin: 0 auto;
				}

				@include media-breakpoint-up(lg) {
					position: absolute;
					left: calc(10vw + 15px);
					bottom: 50px;
					max-width: 280px;
				}
			}
		}
	}
}

&:not(.home) {
	.mini-sab {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
