// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$orange: #ea580c;
$blue-light: #9fd2dd;
$purple-dark: #252149;
$green-dark: #034853;
$brown-light: #ded9cb;

$white: #fff;
$black: #000;

// theme-colors
$primary: $green-dark;
$secondary: $blue-light;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $green-dark;
$link-decoration: underline;
$link-hover-color: $orange;

// typography
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-primary: 'Lato', sans-serif;
$font-family-secondary: 'Poppins', sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.25; // 36px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 600;
$headings-color: $black;

// contextual
$light: $blue-light;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
