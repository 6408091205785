// header
.header {
	z-index: 996;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	&::before {
		content: url('/images/header-home-bg.svg');
		position: absolute;
		top: 137px;
		left: 0;
		right: 0;
		width: 100%;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: '';
			top: 100%;
			background: url('/images/header-vervolg-bg.png') no-repeat;
			background-size: cover;
			aspect-ratio: 1366 / 111;
		}
	}

	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@include media-breakpoint-up(xl) {
					padding-right: 40px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	.header-top {
		padding: 5px 0;
		background-color: $blue-light;

		@include media-breakpoint-up(md) {
			padding: 15px 0;
		}
	}

	.header-middle {
		background-color: $blue-light;

		.container-fluid {
			.container-holder {
				>.column {
					@include media-breakpoint-down(sm) {
						flex-flow: column;
						align-items: flex-end;
					}
				}
			}
		}
	}

	.header-bottom {
		padding-top: 15px;

		@include media-breakpoint-down(md) {
			background-color: $blue-light;
		}

		.wysiwyg {
			max-width: 700px;
			margin: 0 30px 15px 30px;
			color: $white;
			font-weight: 600;
			line-height: 1.25;
			font-family: $font-family-secondary;
			font-size: 24px;

			@include media-breakpoint-down(md) {
				background-color: $blue-light;
			}

			@include media-breakpoint-only(sm) {
				font-size: 30px;
				margin-top: 15px;
				margin-bottom: 0;
			}

			@include media-breakpoint-only(md) {
				font-size: 36px;
			}

			@include media-breakpoint-up(md) {
				margin: 0 auto 0 10vw;
			}

			@include media-breakpoint-only(lg) {
				font-size: 48px;
			}

			@include media-breakpoint-up(xl) {
				font-size: 60px;
			}

			p {
				margin-bottom: 0;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			margin-bottom: 15px;
		}

		.navbar-toggler {
			display: flex;
			align-items: center;
			padding: 0.25rem 0.5rem;
			color: $green-dark;
			font-size: 18px;
			font-weight: 700;
			font-family: $font-family-secondary;
			transition: 0.5s;

			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}

			.navbar-toggler-icon {
				width: 30px;
				text-align: center;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		z-index: 998;
		position: fixed;
		width: 453px;
		height: 318px;
		top: 0;
		left: 0;
		background-image: url('/images/logo-holder.svg');
		background-size: cover;

		@include media-breakpoint-down(sm) {
			position: absolute;
			width: 245px;
			height: 164px;
			background-image: url('/images/logo-holder-mobile.svg');
		}

		@include media-breakpoint-between(md, lg) {
			width: 300px;
			height: 211px;
		}

		@media (min-width: 2000px) {
			position: absolute;
		}

		a {
			img {
				width: 100%;
				max-width: 340px;
				margin: 25px 0 0 50px;

				@include media-breakpoint-down(sm) {
					max-width: 190px;
					margin: 15px 0 0 15px;
				}

				@include media-breakpoint-between(md, lg) {
					max-width: 222px;
					margin: 15px 0 0 35px;
				}
			}
		}
	}

	// top-menu
	.top-menu {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		flex-grow: 0;

		.navbar-nav {
			>.nav-item {
				margin-left: 15px;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				font-family: $font-family-secondary;

				>.nav-link {
					display: flex;
					align-items: center;
					color: $green-dark;

					&:hover {
						color: $white;
					}
				}

				&.active {
					>.nav-link {
						color: $white;
					}
				}
			}

			.nav-item {
				.dropdown-menu {
					padding: 15px;
					border: none;
					border-radius: 2px;
					box-shadow: none;

					&::before {
						border-left-width: 9px;
						border-right-width: 9px;
						border-bottom-width: 8px;
					}

					&::after {
						border-left-width: 9px;
						border-right-width: 9px;
						border-bottom-width: 8px;
					}

					.nav-item {
						font-size: 12px;
						font-weight: 500;
						line-height: 1.2;
						text-transform: none;
						white-space: nowrap;

						.nav-link {
							color: $black;

							&:hover {
								color: $orange;
							}
						}

						&.active {
							.nav-link {
								color: $orange;
							}
						}
					}
				}
			}
		}
	}

	// social
	div.social {
		margin-left: 15px;

		.navbar-nav {
			.nav-item {
				+li {
					margin-left: 10px;
				}

				font-size: 14px;

				.nav-link {
					padding: 0.5rem 0.25rem !important;
					color: $green-dark;

					&:hover {
						color: $white;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		@include media-breakpoint-down(xs) {
			margin-left: 5px;
		}

		.navbar-nav {
			&.language {
				.nav-item {
					font-size: 12px;
					font-weight: 300;

					.nav-link {
						padding: 0.5rem 0.25rem !important;
						color: $green-dark;
						border: none;
						box-shadow: none;

						&:hover {
							box-shadow: none;
							color: $white;
						}
					}

					&.active {
						.nav-link {
							font-weight: 500;
						}
					}
				}
			}
		}
	}

	// main-menu
	.main-menu {
		@include media-breakpoint-down(lg) {
			order: 5;
			width: 100%;
			z-index: 998;
			position: relative;

			.navbar-nav {
				padding: 30px 0 0 30px;

				>.nav-item {
					margin-bottom: 5px;
					font-family: $font-family-secondary;

					>.nav-link {
						display: inline-flex;
						align-items: center;
						padding: 2.5px 0;
						color: $green-dark;
						font-size: 16px;
						font-weight: 700;
						border-bottom: 1px solid transparent;

						&:hover {
							border-bottom-color: $green-dark;
							color: $green-dark;
						}
					}

					&.active {
						>.nav-link {
							border-bottom-color: $green-dark;
							color: $green-dark;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 5px 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							margin-bottom: 0;
							font-size: 14px;
							font-weight: 500;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								>.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					display: flex;
					align-items: center;
					margin-left: 30px;
					font-size: 16px;
					font-weight: 700;
					font-family: $font-family-secondary;

					>.nav-link {
						padding: 0.25rem 0 !important;
						color: $white;
						border-bottom: 1px solid transparent;

						&:hover {
							border-bottom-color: $white;
							color: $white;
						}
					}

					&.active {
						>.nav-link {
							border-bottom-color: $white;
							color: $white;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 15px;
						border: none;
						border-radius: 2px;
						box-shadow: none;
						background: $green-dark;

						&::before {
							border-left-width: 9px;
							border-right-width: 9px;
							border-bottom-width: 8px;
							border-bottom-color: $green-dark;
						}

						&::after {
							border-left-width: 9px;
							border-right-width: 9px;
							border-bottom-width: 8px;
							border-bottom-color: $green-dark;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 500;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $white;

								&:hover {
									color: $blue-light;
								}
							}

							&.active {
								.nav-link {
									color: $blue-light;
								}
							}
						}
					}
				}
			}
		}
	}

	// mobile-menu
	.mobile-menu {
		@include media-breakpoint-down(lg) {
			order: 5;
			width: 100%;
		}
	}

	// book-button
	div.book-button {
		margin-left: 30px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $orange;
			border-radius: 100%;
			width: 75px;
			height: 75px;
			color: $white;
			font-size: 12px;
			font-weight: 700;
			line-height: 1.25;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			font-family: $font-family-secondary;

			@include media-breakpoint-up(md) {
				width: 100px;
				height: 100px;
				font-size: 16px;
			}

			&:hover {
				background-color: $green-dark;
				color: $white;
			}
		}
	}
}

&.home {
	.header {

		// book-button
		div.book-button {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}
}

&:not(.home) {
	.header {
		&::before {
			content: url('/images/header-vervolg-bg.svg');

			@include media-breakpoint-up(md) {
				top: 117px;
			}

			@include media-breakpoint-down(md) {
				content: '';
				top: 100%;
				background: url('/images/header-vervolg-bg.png') no-repeat;
				background-size: cover;
				aspect-ratio: 1366 / 111;
			}
		}

		.header-middle {
			.container-holder {
				>.column {
					@include media-breakpoint-up(md) {
						align-items: flex-start;
					}
				}
			}

			div.book-button {
				margin-bottom: -50px;
			}
		}
	}
}

// force
@include media-breakpoint-up(xl) {
	.header .container-fluid .container-holder>.column .mobile-menu.navbar-collapse {
		display: none !important;
	}
}

&.navbar-collapse-active {
	&::before {
		content: "";
		z-index: 995;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.5);
	}

	.header {
		&::before {
			content: '';
			top: 100%;
			background: url('/images/header-vervolg-bg.png') no-repeat;
			background-size: cover;
			aspect-ratio: 1366 / 111;
		}

		.header-top,
		.header-middle {
			background-color: $brown-light;
		}

		.header-bottom {
			display: none;
		}

		.header-mobile {
			position: relative;
			background-color: $blue-light;

			&::before {
				content: '';
				display: block;
				background: url('/images/header-mobile-bg.png') no-repeat;
				background-size: cover;
				aspect-ratio: 1366 / 111;
			}
		}

		div.book-button {
			display: none;
		}

		div.social {
			.navbar-nav {
				.nav-item {
					.nav-link {
						color: $white;
					}
				}
			}
		}

		.site-switcher {
			.navbar-nav {
				&.language {
					.nav-item {
						.nav-link {
							color: $white;

						}
					}
				}
			}
		}

		.mobile-menu {
			display: block;

			.navbar-nav {
				padding: 30px 0 0 30px;

				>.nav-item {
					margin-bottom: 5px;
					font-family: $font-family-secondary;

					>.nav-link {
						display: inline-flex;
						align-items: center;
						padding: 2.5px 0;
						color: $purple-dark;
						font-size: 12px;
						font-weight: 500;
						text-transform: uppercase;
						border-bottom: 1px solid transparent;

						&:hover {
							border-bottom-color: $purple-dark;
							color: $purple-dark;
						}
					}

					&.active {
						>.nav-link {
							border-bottom-color: $purple-dark;
							color: $purple-dark;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 5px 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							margin-bottom: 0;
							font-size: 12px;
							font-weight: 500;

							.nav-link {
								color: $purple-dark;

								&:hover {
									color: $white;
								}
							}

							&.active {
								>.nav-link {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
	}
}
