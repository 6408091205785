// footer
.footer {
	margin-top: 8vh;
	color: $white;
	font-size: 14px;
	text-align: center;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	a:not(.btn) {
		color: $white;
		text-decoration: none;

		&:hover {
			color: $orange;
		}
	}

	// footer-partners
	.footer-partners {
		background-color: $white;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-wave
	.footer-wave {
		picture {
			display: block;
		}

		@include media-breakpoint-down(lg) {
			.desktop {
				display: none;
			}

			img {
				margin-bottom: -1px;
			}
		}

		@include media-breakpoint-up(xl) {
			.mobile {
				display: none;
			}

			img {
				margin: 0 -2.5px -2.5px -2.5px;
			}
		}
	}

	// footer-outro
	.footer-outro {
		padding: 6vh 0;
		background-color: $green-dark;

		// newsletter-badge
		div.newsletter-badge {
			position: absolute;
			bottom: calc(100% + 75px);

			@include media-breakpoint-down(lg) {
				display: none;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $orange;
				border-radius: 100%;
				width: 150px;
				height: 150px;
				color: $white;
				font-size: 16px;
				font-weight: 700;
				line-height: 1.25;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				font-family: $font-family-secondary;

				&:hover {
					background-color: $purple-dark;
					color: $white;
				}
			}
		}

		// footer-text
		.footer-text {
			p {
				strong {
					font-weight: 700;
				}

				a:not(.btn) {
					color: $blue-light;
					font-weight: 700;

					&:hover {
						color: $orange;
					}
				}

				.btn {
					width: 100%;
					max-width: 240px;
					margin-top: 1rem;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				margin: 0.5rem 0;
				justify-content: center;

				li {
					margin: 0 15px;

					a {
						display: block;
						font-size: 30px;
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		background-color: $green-dark;

		.container-one-column {
			.container-holder {
				.column {
					flex-flow: row wrap;
					justify-content: center;

					>.footer-list {
						margin: 0 30px 30px 30px;

						h5 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	// footer-sites
	.footer-sites {
		padding: 6vh 0;
		background-color: $green-dark;

		.footer-text {
			ul {
				display: flex;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					margin: 0 15px;

					@include media-breakpoint-up(md) {
						margin: 0 50px;
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;
		background-color: $green-dark;

		// footer-link
		.footer-link {
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					font-weight: 300;

					@include media-breakpoint-up(md) {
						margin: 0 15px;
					}

					a {
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}
